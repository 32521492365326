<svelte:options tag="kd-city-picker" />

<script>
  import { onMount } from 'svelte'
  import _provinces from './province.min.json'
  import cities from './city.min.json'
  export let show = false
  export let cancelBtn = '取消'
  export let okBtn = '确定'
  export let province = ''
  export let city = ''
  export let country = true

  let root = arguments[0]
  let level = []
  let groups = [{items: []}, {items: []}]
  let provinces = []

  const cdn = 'https://unpkg.com/province-city-china/dist'

  onMount(() => {
  })

  $: {
    // 增加显示全国
    provinces = country ? [{"c":"000000","n":"全国","p":"00"}].concat(_provinces) : _provinces
    // console.log(country, provinces)
    // groupIndex: 0
    groups[0].items = provinces.map(i => ({label: i.n, code: i.p}))
    let index0 = index(groups[0].items, province)
    if (groups[0].items.length > 0 && index0 == -1) {
      index0 = 0
    }
    province = index0 >= 0 ? groups[0].items[index0].label : ''
    groups[0].defaultIndex = index0
    // groupIndex: 1
    if (index0 >= 0) {
      const items = cities.filter(i => i.p == provinces[index0].p).map(i => ({label: i.n, code: i.c}))
      groups[1].items = items.length > 0 ? items : [{label: provinces[index0].n, code: provinces[index0].c}]
      let index1 = index(groups[1].items, city)
      if (groups[1].items.length > 0 && index1 == -1) {
        index1 = 0
      }
      city = index1 >= 0 ? groups[1].items[index1].label : ''
      groups[1].defaultIndex = index1
    }
  }

  function index(items, name) {
    for (const index in items) {
      if (items[index].label == name) {
        return index
      }
    }
    return -1
  }

  function groupchange(e) {
    // console.debug('groupchange', e.detail)
    const { groupIndex, item, selected } = e.detail
    if (selected >= 0 && groupIndex == 0 && province != item.label) {
      province = item.label
    }
    if (selected >= 0 && groupIndex == 1 && city != item.label) {
      city = item.label
    }
    root.dispatchEvent(custom_event('groupchange', e.detail))
  }

  function change(e) {
    const detail = {province, city}
    console.debug('change', e.detail, detail)
    root.dispatchEvent(custom_event('change', detail))
  }

  function close(e) {
    console.debug('close', e.detail)
    show = false
    root.dispatchEvent(custom_event('close', e.detail))
  }

  function custom_event(type, detail) {
    const e = document.createEvent('CustomEvent');
    e.initCustomEvent(type, false, false, detail);
    return e;
  }


</script>

<kd-picker on:groupchange={groupchange} on:change={change} on:close={close} groups={groups} show={show} cancelBtn={cancelBtn} okBtn={okBtn}>
  <slot />
</kd-picker>

