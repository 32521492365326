<svelte:options tag="kd-audio" />


<script>
  export let src;
  export let title;
  export let desc;
  export let audio;
  export let duration;
  export let seekable;

  let currentTime;
  let progress;
  let playing

  function pad(t) { return t < 10 ? '0' + t : t }
  function time(duration) {
    const hour = Math.floor(duration / 3600)
    const minutes = Math.floor(duration % 3600 / 60)
    const seconds = Math.ceil(duration) % 60
    return `${hour > 0 ? pad(hour) : '' }${pad(minutes)}:${pad(seconds)}`
  }

</script>

<section class="kd_audio">
  <audio
    on:timeupdate={e => {
      progress = (currentTime / duration) * 100
    }}
    bind:this={audio} bind:duration={duration} bind:currentTime={currentTime} bind:seekable={seekable}
    src={src}
    style="display: none;"
  >
    <track kind="captions" />
  </audio>
  <span aria-labelledby="语音" class="appmsg_card_context appmsg_card_active db audio_card">
    <strong class="audio_card_title" aria-describedby="语音标题" role="link">{title}</strong>
    <span class="audio_card_desc">{desc}</span>
    <span class="weui-flex">
      <span class="weui-flex__item">
        <span class="audio_card_opr">
          <span class="audio_card_progress_wrp">
            <span class="audio_card_progress">
              <span style="width:0%" class="audio_card_progress_inner"></span>
              <span class="audio_card_progress_buffer" style="width:{progress}%;"></span>
              <span class="audio_card_progress_loading" style="display:none;"></span>
            </span>
            <span class="audio_card_progress_handle" style="display:{seekable ? 'inline' : 'none'};left:{progress}%;"></span>
          </span>
          <span class="audio_card_tips" aria-labelledby="时长">
            <em class="audio_card_length_current" aria-hidden="true">{currentTime ? time(currentTime) : '00:00'}</em>
            <em class="audio_card_length_total">{duration ? time(duration) : ''}</em>
          </span>
        </span>
      </span>
      <span on:click={e => {
        //console.log('click play btn', duration, currentTime, area.classList)
        if (audio.paused) {
          audio.play().then(() => { 
            playing = true
          })
        } else {
          audio.pause()
          playing = false
        }
      }} aria-labelledby="播放开关" class="audio_card_switch">
        <em class="weui-audio-btn" style="{playing ? 'animation: weuiAudioPlaying 1.2s step-start infinite;': ''}" role="button"></em>
      </span>
    </span>
  </span>
</section>


<style>
/*weixin audio*/
.audio_area {
    margin: 16px 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

.audio_card {
    padding: 20px 20px 16px;
    line-height: 1.4;
    display: block;
}
.appmsg_card_context {
    position: relative;
    background-color: #f7f7f7;
    border-radius: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.audio_card_title {
    display: block;
    font-weight: 700;
    font-size: 17px;
    color: rgba(0,0,0,0.9);
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
.audio_card_desc {
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    padding: 8px 72px 0 0;
}
.weui-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}
.weui-flex__item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}
.audio_card_opr {
    display: block;
    padding-top: 14px;
}
.audio_card_progress_wrp {
    position: relative;
    display: block;
    height: 2px;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.audio_card_progress {
    display: block;
    height: 100%;
    background: rgba(0,0,0,0.1);
}
.audio_card_progress_inner {
    height: 100%;
    background: rgba(0,0,0,0.9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.audio_card_progress_buffer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.19);
}
.audio_card_progress_loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.audio_card_progress_handle {
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
    z-index: 2;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    margin: -7px 0 0 -7px;
}
.audio_card_progress_handle::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    top: 50%;
    left: 50%;
    margin-top: -3.5px;
    margin-left: -3.5px;
    background: rgba(0,0,0,0.9);
    border-radius: 50%;
}
.audio_card_tips {
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    padding-top: 7px;
}
.audio_card_tips em {
    font-style: normal;
}
.audio_card_length_total {
    float: right;
}
.audio_card_length_total:before {
    position: absolute;
    left: -9999em;
    content: "总时长";
}
.audio_card_switch {
    padding-left: 20px;
}
.audio_card_switch .weui-audio-btn {
    margin-top: 0px;
}
.weui-audio-btn {
    content: "";
    display: block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #07c160;
    -webkit-mask: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M14.9 2A14.17 14.17 0 0 1 19 12a14.17 14.17 0 0 1-4.1 10l-1.485-1.5A12.036 12.036 0 0 0 16.9 12c0-3.233-1.267-6.259-3.485-8.5L14.899 2zm-3.465 3.5A9.21 9.21 0 0 1 14.1 12a9.21 9.21 0 0 1-2.665 6.5L9.95 17A7.077 7.077 0 0 0 12 12a7.077 7.077 0 0 0-2.05-5l1.485-1.5zM7.97 9a4.251 4.251 0 0 1 1.23 3 4.25 4.25 0 0 1-1.23 3L5 12l2.97-3z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: currentColor;
}
.playing {
    animation: weuiAudioPlaying 1.2s step-start infinite;
    -webkit-animation: weuiAudioPlaying 1.2s step-start infinite;
}

@-webkit-keyframes weuiAudioPlaying{
  30%{
    -webkit-mask-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M7.97 15a4.251 4.251 0 0 0 1.23-3 4.25 4.25 0 0 0-1.23-3L5 12l2.97 3z'/%3E%3C/svg%3E");
  }
  31%{
    -webkit-mask-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M11.435 5.5A9.21 9.21 0 0 1 14.1 12a9.21 9.21 0 0 1-2.665 6.5L9.95 17A7.077 7.077 0 0 0 12 12a7.077 7.077 0 0 0-2.05-5l1.485-1.5zM7.97 9a4.251 4.251 0 0 1 1.23 3 4.25 4.25 0 0 1-1.23 3L5 12l2.97-3z'/%3E%3C/svg%3E");
  }
  61%{
    -webkit-mask-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M11.435 5.5A9.21 9.21 0 0 1 14.1 12a9.21 9.21 0 0 1-2.665 6.5L9.95 17A7.077 7.077 0 0 0 12 12a7.077 7.077 0 0 0-2.05-5l1.485-1.5zM7.97 9a4.251 4.251 0 0 1 1.23 3 4.25 4.25 0 0 1-1.23 3L5 12l2.97-3z'/%3E%3C/svg%3E");
  }
  62%{
    -webkit-mask-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M14.9 2A14.17 14.17 0 0 1 19 12a14.17 14.17 0 0 1-4.1 10l-1.485-1.5A12.036 12.036 0 0 0 16.9 12c0-3.233-1.267-6.259-3.485-8.5L14.899 2zm-3.465 3.5A9.21 9.21 0 0 1 14.1 12a9.21 9.21 0 0 1-2.665 6.5L9.95 17A7.077 7.077 0 0 0 12 12a7.077 7.077 0 0 0-2.05-5l1.485-1.5zM7.97 9a4.251 4.251 0 0 1 1.23 3 4.25 4.25 0 0 1-1.23 3L5 12l2.97-3z'/%3E%3C/svg%3E");
  }
  100%{
    -webkit-mask-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E  %3Cpath fill='%2307C160' d='M14.9 2A14.17 14.17 0 0 1 19 12a14.17 14.17 0 0 1-4.1 10l-1.485-1.5A12.036 12.036 0 0 0 16.9 12c0-3.233-1.267-6.259-3.485-8.5L14.899 2zm-3.465 3.5A9.21 9.21 0 0 1 14.1 12a9.21 9.21 0 0 1-2.665 6.5L9.95 17A7.077 7.077 0 0 0 12 12a7.077 7.077 0 0 0-2.05-5l1.485-1.5zM7.97 9a4.251 4.251 0 0 1 1.23 3 4.25 4.25 0 0 1-1.23 3L5 12l2.97-3z'/%3E%3C/svg%3E");
  }
}
/*weixin audio*/

</style>
