<svelte:options tag="kd-calculator" />


<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let host = 'https://kaidanzhushou.com'
  let groups = [{
    items: [{
      label: "北欧"
    }, {
      label: "现代简约"
    }, {
      label: "轻奢"
    }, {
      label: "新中式"
    }, {
      label: "欧式"
    }, {
      label: "美式"
    }, {
      label: "田园"
    }, {
      label: "日韩"
    }, {
      label: "地中海"
    }],
    defaultIndex: 0,
  }]
  let style = '北欧'
  let size
  let name
  let telephone

  let root = arguments[0]

  root.$on('error', function(e) {
    console.log('onerror', e)
    root.dispatchEvent(e)
  })
  root.$on('success', function(e) {
    root.dispatchEvent(e)
  })
  function validate () {
    return new Promise((resolve, reject) => {
      console.log('submit', style, size, name, telephone)
      if (!style){
        reject('请选择装修风格')
      }
      if (!size){
        reject('请输入您的房屋面积')
      }
      if (!name){
        reject('请输入您的名字')
      }
      if (!telephone){
        reject('请输入您的手机号')
      }
      if (!/^1[3-9](\d{9})$/.test(telephone)){
        reject('输入的手机号不正确')
      }
      resolve()
    })
  }
  function submit(e) {
    e.preventDefault()
    validate().then(() => {
      return fetch(`${host}/api/calculator`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({style, size, name, telephone}),
      }).then(res => res.json()).then(res => {
        if (res.code === 0) {
          return Promise.resolve(res)
        }
        return Promise.reject(res.msg)
      })
    }).then(res => dispatch('success', {res})).catch(e => dispatch('error', {msg: e.length ? e : `${e}`}));
  }

  function pickerchange(e) {
    console.log('pickerchange', e.detail)
    style = groups[0].items[e.detail.selected[0]].label
  }

</script>

<section class="kd-calculator">
  <img src="{host}/static/calculator-top.png" alt="" />
  <div class="title">您的家装预算</div>
  <img src="{host}/static/calculator-number.gif" alt="" />
  <div class="desc">
    <div>人工费：？？？元</div>
    <div>材料费：？？？元</div>
  </div>
  <div class="desc" style="margin-bottom: 0.3rem;">
    <div>设计费：？？？元</div>
    <div>质检费：？？？元</div>
  </div>
  <form on:submit={submit}>
    <div class="row">
      <span class="label">装修风格</span>
      <kd-picker on:change={pickerchange} groups={JSON.stringify(groups)}>{style}</kd-picker>
      <i class="bottom" >
    </div>
    <div class="row">
      请输入房屋面积
      <input bind:value={size} type="number" min="1" max="100000" />
      <span class="extra">m<sup>2</sup></span>
    </div>
    <div class="row">
      请输入您的名字
      <input bind:value={name} />
    </div>
    <div class="row">
      请输入您的手机号
      <input type="number" bind:value={telephone} min="10000000000" max="200000000000" />
    </div>
    <div class="row button" on:click={submit} style="background-image:url({host}/static/calculator-btn.png);">
      <button type="submit"></button>
    </div>
  </form>
</section>


<style lang="less">
.kd-calculator {
  background: #FFFFFF;
  box-shadow: 0rem 0.04rem 0.08rem 0rem #0091FF;
  border-radius: 0.16rem;
  padding-bottom: 0.32rem;
  img {
    &:first-child {
      border-radius: 0.16rem 0.16rem 0 0;
    }
    width: 100%;
  }
  .title {
    height: 0.4rem;
    font-size: 0.27rem;
    font-weight: 700;
    color: #000000;
    line-height: 0.4rem;
    text-align: center;
    padding: 0.3rem 0 0.26rem;
    position: relative;
    &::before, &::after{
      content: "";
      display: inline-block;
      position: absolute;
      background: #979797;
      height: 0.01rem;
      width: 1.59rem;
      top: 0.43rem;
      left: 0.48rem;
    }
    &::after{
      right: 0.48rem;
      left: auto;
    }
  }
  .desc {
    font-size: 0.26rem;
    color: #999999;
    display: flex;
    &>div{
      width: 50%;
      box-sizing: border-box;
      text-align: center;
      line-height: 0.4rem;
    }
  }
  .row{
    font-size: 0.35rem;
    font-weight: 400;
    line-height: 0.86rem;
    height: 0.86rem;
    margin: 0.1rem 0.12rem 0.1rem 0.16rem;
    color: rgba(0, 0, 0, 0.5);
    background: #EDEDED;
    border-radius: 0.06rem;
    padding: 0 0.16rem 0 0.28rem;
    display: flex;
    justify-content: space-between;
    &>kd-picker{
      font-size: 0.32rem;
      color: #000000;
      padding-right: 10px;
    }
    .label{
      flex: 1;
    }
    .bottom{
      margin-top: 16px;
      height: 0px;
      width: 0px;
      border-width: 5px;
      border-style: solid;
      border-color:#666666 transparent transparent transparent;
    }
    &>input{
      background: transparent;
      border: none;
      padding: none;
      text-align: right;
      outline-style: none;
      width: 40%;
      font-size: 0.32rem;
    }
    &>button{
      display: none;
    }
    .extra{
      color: #000000;
      display: inline-flex;
    }
  }
  .button{
    background-size: 100% 100%;
  }
}
</style>
